import React, { useState, useRef, useEffect } from "react"
import classNames from "classnames"
import _debounce from 'lodash.debounce'; 
import SVG from 'react-inlinesvg';

import iconPlus from "../images/plus.svg";
import iconPlusWhite from "../images/plus-white.svg";

import Collapse from './wrappers/collapse';
import { CustomSvg } from "./CustomSvg";

const Faq = ({ heading, children, shouldNotLazyLoad, showWhitePlus, className, fontColorLightMode}) => {
  const [isOpen, setIsOpen] = useState(false);
  const refBody = useRef();
  return (
    <div className={"faq" + " " + className }>
      <div className={classNames("faq__heading", { "open": isOpen })} onClick={() => setIsOpen(!isOpen)}>
        {heading}
        <CustomSvg color={fontColorLightMode} className={"faq__icon"} />
      </div>
      <Collapse isOpen={isOpen}>
        <div className={classNames("faq__body rich-text", { "open": isOpen })} ref={refBody}>
          {children}
        </div>
      </Collapse>
    </div>
  )
}

export default Faq
