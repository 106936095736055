import React from "react"
import { useContext } from "react";
import { graphql } from 'gatsby';
import Markdown from 'markdown-to-jsx';

import { getSinglePage } from "../utils";
import { GlobalStateContext } from "../context/GlobalContextProvider"
import Layout from "../components/wrappers/layout"
import SEO from "../components/seo"
import SiteHeaderAlt from "../components/wrappers/site-header-alt"
import Header from '../components/header';
import Faq from "../components/faq";
import Link from "../components/link";
import { CustomHr } from "../components/CustomHr";


const FaqDetail = (props) => {
  const { footers, headers, faqsPage, theme, general } = props.data.strapi;
  const { copyright } = faqsPage;
  const { generalBackgroundColorDarkMode, generalBackgroundColorLightMode , fontColorDarkMode, fontColorLightMode } = general; 
  const language = props.pageContext.language;
  const header = headers && headers.find((header)=>  header.language === language ); 
  const footer = footers && footers.find((footer)=>  footer.language === language ) || {}; 
  const state = useContext(GlobalStateContext);
  const darkMode = state.darkModePreference === 'moon'; 
  const seoTitle = faqsPage && faqsPage.seo && faqsPage.seo.metaData?.metaTitle ;
  const seoDescription = faqsPage &&  faqsPage.seo && faqsPage.seo?.metaData?.metaDescription;
  const seoKeywords = faqsPage &&  faqsPage.seo && faqsPage.seo?.metaData?.keywords; 
  const webpages = faqsPage && faqsPage.webPages || {}; 
  const allPages = props.data?.allSitePage?.nodes; 
  const redirectTo = getSinglePage(webpages, allPages); 
  const faqsBody = faqsPage?.body;
  const faqCategories = faqsPage.faqCategories;
  const {darkTheme, heading} = faqsPage;
  const switchToDarkMode = theme.switchToDarkMode;
  const sectionBackgroundColor = darkMode ? generalBackgroundColorDarkMode : generalBackgroundColorLightMode; 
  const websiteFontColor = darkMode ? fontColorDarkMode : fontColorLightMode; 
  if (copyright) {
    footer.copyright = copyright;
  }

  return (
    <Layout header={header} footer={footer} language={language} redirectTo={redirectTo}>
      <SEO
        websiteFontColor={websiteFontColor} 
        template="faqsPage" 
        title={seoTitle} 
        description={seoDescription} 
        keywords={seoKeywords} 
        darkTheme={faqsPage.darkTheme}
        darkMode={darkMode} 
        websiteBackgroundColor={sectionBackgroundColor}
      />
      <SiteHeaderAlt header={header} redirectTo={redirectTo}>
        <section className="section section-small-margin offset-md-1" style={{backgroundColor: sectionBackgroundColor}}>
           {heading && (
             <Header 
               heading={heading ? heading : ''} 
               withFullWidth={true} 
             />
           )}  
           {faqsBody && faqsBody.length > 0 && (
             <div className="container">
                {faqsBody && faqsBody.length > 0 && (
                <div className="row what-create-digital">
                  <div className="col-md-8 offset-md-1 col-lg-8 offset-lg-1">
                    <div className="about__item-body rich-text">
                      <Markdown options={{
                        overrides: {a: {
                          component: Link
                        },                           
                        hr: { 
                            component: CustomHr,
                            props: {
                              borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                            }
                          }  
                       }
                      }}>
                        {faqsBody}
                     </Markdown>
                    </div>
                  </div>
                </div>
                )}
             </div>
           )}
        </section>
        {faqCategories && faqCategories.length > 0 && (
          <section className="section faq-categories offset-md-1" style={{backgroundColor: sectionBackgroundColor}}>
            {faqCategories.map((faqCategory) => {
              return (
                <div className="faq-categories__item">
                  <Header 
                    heading={faqCategory.heading ? faqCategory.heading : ''} 
                    withFullWidth={true} 
                    largeHeadingClassName={"header--secondary"}
                  />
                  <div className="container">
                    <div className="row">
                      {faqCategory.faqs.map((faq) => {
                        return (
                            <div className="col-tw-12 col-md-9 offset-md-1 col-lg-8 offset-lg-1">
                              <Faq heading={faq.heading} showWhitePlus={darkTheme || switchToDarkMode || darkMode} className={"faq--small-margin"} fontColorLightMode={fontColorLightMode}>
                                  <Markdown options={{
                                    overrides: {a: {
                                      component: Link
                                    },                           
                                    hr: { 
                                        component: CustomHr,
                                        props: {
                                          borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                                        }
                                      }  
                                    }
                                  }}>
                                  {faq.richText || ''}
                                </Markdown>
                              </Faq>
                            </div>
                            )
                      })}
                    </div>
                  </div>
                </div>
              )
            })}
          </section>
        )}
      </SiteHeaderAlt>
    </Layout>
  )
}

export const pageQuery = graphql`
  query FaqsPage($language: String!, $id: ID!) {
    allSitePage {
      nodes {
        path
      }
    }
    strapi {
      general {
        generalBackgroundColorDarkMode
        generalBackgroundColorLightMode
        fontColorDarkMode
        fontColorLightMode
      }
      faqsPage(id: $id) {
       copyright
       heading
       body 
       darkTheme
       faqCategories {
         heading
         faqs {
          richText
          id
          heading
         }
       }
       webPages {
         about_us {
            language
          }
          contact {
            language
          }
          case_study {
            slug
            language
          }
          careers_overview {
            language
          }	
          services_overview {
            language
          }
          blog {
            language
            slug
          }
          blogs_overview {
            language
          }
          cases_overview {
            language
          }
          career {
            language
            slug
          }
          homepage {
            slug
            language
          }
          service_design {
            slug
            language
          }
          service_technical {
            slug
            language
          }
          service_casey_new {
            slug
            language
          }
          service_casey {
            slug
            language
          }
          plainpage {
            language
            slug
          }
        }
        seo {
          metaData {
            metaDescription
            metaTitle
          }
        }
      }
      theme {
        switchToDarkMode
      }
      headers(where: {language: $language}) {
        ...SiteHeader
      }
      footers(where: { language: $language }) {
        ...SiteFooter
      }
    }
  }
`

export default FaqDetail;
